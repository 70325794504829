.primary-button {
    background: rgb(9, 148, 148) !important;
    border: none;
    color: white;
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    border-radius: 2px;
    font-size: 0.8rem;
    cursor: pointer;
}
.primary-button:hover {
    opacity: 0.8;
}

.main-text-color {
    color: rgb(9, 148, 148);
}

.bg-silver {
    background: #f5f7f9;
}
.timeline-item {
    position: relative;
}

.timeline-circle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: rgb(9, 148, 148);
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.list li {
    font-size: 1rem;
    color: var(--gray-600);
}

.hidden-div {
    display: block;
}

.block-div {
    display: none !important;
}

.bg-secondary {
    background: #263238;
}

.menu-button {
    background: #ffffff;
    border-radius: 2px;
    border-color: rgb(9, 148, 148);
    color: rgb(9, 148, 148);
    padding: 0.7rem !important;
    font-weight: 700;
}

.contact-button {
    background: rgb(9, 148, 148);
    border-radius: 2px;
    border-color: rgb(9, 148, 148);
    color: #ffffff;
}
.contact-button:hover {
    opacity: 0.8;
}

.max-screen {
    max-width: 1920px;
}

#rfs-btn {
    border: none;
    background: #f5f7f9;
}

.custom-flags-select ul {
    border: none !important;
    background: #f5f7f9;
}
.custom-flags-select ul li:hover {
    background: #ffffff;
}

@media (max-width: 768px) {
    /* Đặt màu sắc là đỏ khi kích thước màn hình nhỏ hơn 768px */
    .hidden-div {
        display: none !important;
    }
    .block-div {
        display: block !important;
    }

    .list li {
        font-size: 0.875rem;
    }
}

.App {
    max-width: 1920px;
}
